// user
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";


const list = require('../../static/data-user-actions.json').data

export default function UserActionsModule() {

  const asset = GetUserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const usxx = GetUserForm();
  console.log(asset)

  const [loading, setLoading] = useState(true)
  const nextlink = useNavigate();
  // const [data, setData] = useState()
  const data = list.filter(x => x.user.includes(asset ? asset.form: 'indx')).filter(x=>x.actv);

  const handleClick = (item) => {
    nextlink(item)
  }
    
  return (
    <>

      <div className="row row-cols-3 row-cols-md-3 g-1">
      {data && data.map((item, i) => (item.actv ?
        <div className="col text-center mb-1" key={i}>
          
          
            <div className={`py-2 back-color-wite hitone rounded-wd cursor text-color-${item.actv ? 'next' : 'lite'} `} 
              onClick={() => handleClick(`/${usxx}/${item.role.includes(asset.role) ? `${item.link}` : 'home'}`)}>
        
              <i className={`m-0 ${item.icon} ${item.role.includes(asset.role) ? '' : 'text-color-lite'}`}  
                style={{fontSize:"2.4em"}}>
              </i>
              
              <div className={`text-small m-0 text-uppercase ${item.role.includes(asset.role) ? 'text-color-tone' : 'text-color-lite'}`}>
                <div className="text-small d-none d-md-block">{item.name}</div>
                <div className="text-mini d-md-none">{item.name}</div>
              </div>

            </div>     
          
          

        </div>
      : ''))}
      </div>

    </>
  );

}