// network
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoader from "../webx/webb-loader";
import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { NetworkListHost } from "../../services/srvc-network-realm";

// lists --------------->


// code --------------->

export default function NetworkListModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  console.log (asset)
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState('')

  const [data, setData]=useState([]);

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')
  
  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        const result = await NetworkListHost({ 
          data: {
            user: asset.item,
            index: index, items: items,
            filters: {name: search}
            },
          user: asset.item || '******'
          })
          console.log (result.data)
          console.log (search)
          if (result.data) {
            const conx = result.data.list
            setData(conx.sort((a,c) => a.name - c.name))
            setTotal(Math.ceil(result.data.count/ items))
            setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)
          } 

        setLoading(false);
      }
      fetchData()
    } else {}
  },[search, index, items]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }
  
  // if (!loading && (!data || data.length === 0)) { return ( <> No Data </> ) }

  return (
    <>
    {/* search-box */}
    <div className={'rounded-wd'}>
      
      <div className="form-group mb-3">  
        <input type="text" 
          className="form-control height-md border-none "              
          value={search}
          onChange={({ target }) => {setSearch(target.value); }}
          // disabled //={data.length==0}
          placeholder="Search">
        </input>
      </div>

    </div>

    {/* loader */}
    <div className={loading ? '' : 'd-none'}>
      <WebbLoader/>
    </div>

    {/* no search data */}
    <div className={!loading && search !== "" && data.length === 0 ? '': 'd-none'}>
      {/* <span><i className="bx bxs-circle text-color-danger"></i></span>
      <span className="ms-1"></span> */}
      <span>No Data</span>
    </div>

    {/* data */}
    <div className={!loading && (data && data.length > 0) ? 'rounded-wd border border-light back-color-wite': 'd-none'}>
      
      <div className="d-flex cursor p-2 px-3 text-small border-bottom">

        <div className="me-auto">
          <p className="text-bold m-0"> {'Name'}</p>
        </div>

        <div className="text-center">{'CHECK'}</div>

      </div>
      
      {data && data.length > 0 && data.map((item, i) => ( 

        <div className={i < data.length-1 ? 'border-bottom border-light' : ''} key={i}>
          
          <div className="d-flex cursor p-2 px-3">

            <div className="me-auto">
              <p className="text-bold text-sm m-0">{item.name || 'Alia Summers'}</p>
              <p className="text-small text-sm m-0 mb-1">{item.mail}</p>
              <p className="text-small text-sm m-0">Ticket: {item.ticket.name}</p>
              <p className="text-small text-sm m-0">Event: {item.event.name}</p>
            </div>

            <div className="text-center text-small p-2">
              <i className={`bx bxs-circle text-mini text-color-${item.checkin ? 'success' : 'tint'}`}></i>
            </div>

          </div>

        </div>

      ))}
    </div>

    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length ===0 ? 'd-none' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>
  
  </>

  )
}